import { SubAreaLine } from "../../circalind/contentManager/SubAreaLine";
import { useEffect, useMemo, useState } from "react";
import { Area, AreaAndRoleInfo, InternalGroup, Project, User } from "../../circalind/dataTypes/generated";
import { areaImages } from "../areas";
import { UserSelect } from "../dialogs/UserSelect";
import { ProjectSelect } from "../dialogs/ProjectSelect";
import { CircalindApi } from "../../circalind/circalindApi";

import "./SubAreaOverview.scss";
import { sendOlert } from "../../olert/Olert";

const areas: Array<Area> = ["governance", "service", "checkups"];

export const SubAreaEdit = () => {
    const [selectedArea, setSelectedArea] = useState<Area>();
    const [selectedProject, setSelectedProject] = useState<number>(-1);
    const [selectedUser, setSelectedUser] = useState<number>(-1);
    const [selectedSubArea, setSelectedSubArea] = useState<number>();
    const [users, setUsers] = useState<Array<User>>([]);
    const [internalGroups, setInternalGroups] = useState<Array<InternalGroup>>([]);
    const [projects, setProjects] = useState<Array<Project>>([]);
    const [areaAndRoleInfo, setAreaAndRoleInfo] = useState<AreaAndRoleInfo | null>(null);

    useEffect(() => {
        const loadData = async () => {
            setUsers(await CircalindApi.getAllUsersOfMyGroup());
            setInternalGroups(await CircalindApi.getInternalGroups());
            setProjects(await CircalindApi.getProjects());
            setAreaAndRoleInfo(await CircalindApi.getAreaAndRoleInfo());
        };

        loadData();
    }, []);

    const subAreaFilter = useMemo(() => selectedArea ? {
        groupId: -1,
        area: selectedArea,
        targetProjectId: selectedProject === -1 ? null : selectedProject,
        targetUserId: (selectedProject !== -1 || selectedUser === -1) ? -1 : selectedUser,
        targetInternalGroupId: null
    } : null, [selectedArea, selectedProject, selectedUser]);

    const renderUserAndProject = () => {
        return <div className="user-project-selection">
            <div className="selection-column">
                <label>Entscheider wählen</label>
                <UserSelect valueUserId={selectedUser === -1 ? undefined : selectedUser} users={users} useName={true} onSelect={u => setSelectedUser(u ? u.id : -1)} />
            </div>
            <div className="selection-column">
                <label>Projekt wählen</label>
                <ProjectSelect projects={projects.filter(p => p.ownerId === selectedUser)} onSelect={(e) => setSelectedProject(e ? e.id : -1)} valueProjectId={selectedProject} />
            </div>
        </div>;
    };

    const renderArea = (area: Area) => {
        const title = areaAndRoleInfo ? areaAndRoleInfo.areaTitles[area] : area;
        const updateSelectedArea = () => {
            setSelectedArea(area);

            if (selectedArea !== area) {
                setSelectedSubArea(undefined);
            }
        };

        return <div key={area} className={`header-element ${selectedArea === area ? "selected" : ""}`}>
            <div className="header-image" onClick={updateSelectedArea}>
                {areaImages[area] ?
                    <img src={areaImages[area]} alt={title} />
                    : <span>{title}</span>
                }
            </div>
            <div className="header-title">{title}</div>
        </div>
    };

    const isTemplateUserOfAnyInternalGroup = internalGroups.some(ig => selectedUser !== -1 && ig.templateUserId === selectedUser);
    const isGovernance = selectedArea === "governance";

    const distributeSubAreas = async () => {
        if (selectedArea) {
            let result = await CircalindApi.distributeSubAreas(selectedProject, selectedArea);

            if (result) {
                sendOlert("Erfolgreich", "Die Rollen/Dienstleistungen wurden erfolgreich an den Personenkreis verteilt.", "Success");
            }
        }
    };

    return <div className="card subarea-edit">
        <h3>Rollen Dienstleistungen</h3>
        {renderUserAndProject()}
        <div className="area">
            {areas.map(renderArea)}
        </div>
        {selectedProject > -1 && subAreaFilter && <SubAreaLine edit={true} filter={subAreaFilter} permissions={[]} selected={selectedSubArea ? selectedSubArea : -1} onSelected={id => setSelectedSubArea(id?.id)} />}
        {selectedProject === -1 && <p>Bitte wählen Sie einen Entscheider und ein Projekt.</p>}
        {selectedProject > -1 && isTemplateUserOfAnyInternalGroup && isGovernance && <button onClick={distributeSubAreas}>An Personenkreis verteilen</button>}
    </div>
};