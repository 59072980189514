import { sendOlert } from "../olert/Olert";
import { decodeJwt } from "jose";
import { getToken, setToken } from "../utils/Auth";
import { Answer, CompassOverview, CompassType, Group, GroupUser, InfoRequest, Project, Question, Questions, ResetPass, ToDo, UpdateQuestions, Info, Permissions, InternalGroup, DeskItem, GetContentQuestionFilter, Poll, PollAnswer, AreaAndRoleInfo, GraphImage, LicenseMetaData, Customer, CircaPackage, UpdatePaid, SubArea, SubAreaFilter, GetContentQuestionFilterForSubArea, ProjectChildPair, ProjectWithChildren, ProjectWayEntry, PasswordCheck, GetWayEntry, UpdateTags, PackagePrice, Response, User, Excel, Filter, IdItem, IdPhaseItem, IdValueItem, SettingsInvoice, ProjectImage, ProjectFamily, ProjectFamilyTuple, GetPostQuestions, UpdateGroupLogo, GraphImageFor, GetManagementPhaseContent, ManagementPhase, ManagementPhaseContent, Area, TransferContent, GetCompass, CustomerAndManager, OrderRequest, Tax, RangeQuery, OrderItem, AnalysisOrder, TypeCustomer, LicenseTypes, AnalysisPackage, TotalLicenses } from "./dataTypes/generated";

export class CircalindApi {
    public static serverURL = process.env.REACT_APP_API_URL + '/circalind/'

    private static postRequest = async (path: string, payload: any, ignoreServerMessage?: boolean): Promise<string | null> => {
        const currentToken: string = getToken("token-circalind") != null ? getToken("token-circalind")! : "";
        const response = await fetch(this.serverURL + path, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'token': currentToken },
            body: payload !== undefined ? JSON.stringify(payload) : undefined,
            credentials: 'include'
        })
        if (response.status === 200) {
            try {
                const res: Response = await response.json();
                if (res.token) {
                    try {
                        decodeJwt(res.token);
                        setToken("token-circalind", res.token);
                    } catch (e) {
                        console.error("No real token given");
                    }
                }
                if (res.success) {
                    return res.payload;
                } else {
                    if (!ignoreServerMessage) {
                        console.log(path);
                        sendOlert("WARNING", res.message, "Warning", 30000)
                    }
                }
            } catch {
                return null;
            }
        } else if (response.status === 401) {
            sendOlert("WARNING", "Sie sind nicht eingelogt", "Warning")
        } else {
            sendOlert("ERROR", "Fehler beim Senden eines Requests", "Error", 30000)
        }
        return null;
    }

    public static getAreaAndRoleInfo = async (): Promise<AreaAndRoleInfo> => {
        const response = await this.postRequest("areas/get", null);

        if (response !== null) {
            return JSON.parse(response);
        }

        throw new Error("No area data available");
    }

    public static getPasswordCheck = async (): Promise<PasswordCheck | null> => {
        const response = await this.postRequest("passwordCheck/get", null);
        if (response !== null && response !== undefined) {
            return JSON.parse(response);
        } else {
            return null;
        }
    }

    public static setPasswordCheck = async (pc: PasswordCheck): Promise<true> => {
        const response = await this.postRequest("passwordCheck/set", pc);

        return JSON.parse(response!);
    }

    public static distributeSubAreas = async (projectId: number, area: Area): Promise<boolean> => {
        let response = await this.postRequest("subAreas/distribute", { projectId, area });

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static getMySubAreas = async (): Promise<Array<SubArea>> => {
        const response = await this.postRequest("subAreas/my", undefined);

        if (response !== null) {
            return JSON.parse(response);
        }

        throw new Error("No area data available");
    }

    public static getSubAreas = async (filter: SubAreaFilter): Promise<Array<SubArea>> => {
        const response = await this.postRequest("subAreas/get", filter);

        if (response !== null) {
            return JSON.parse(response);
        }

        throw new Error("No area data available");
    }

    public static deleteSubArea = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("subAreas/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static addOrUpdateSubArea = async (sa: SubArea): Promise<number> => {
        const response = await this.postRequest("subAreas/addOrUpdate", sa);
        if (response) {
            return JSON.parse(response);
        }
        throw new Error("Missing response");
    }

    public static login = async (user: string, secret: string): Promise<boolean> => {
        const payload: User = { id: -1, mail: user, secret: secret, roles: ["USER"], firstName: "", lastName: "" };
        const response = await this.postRequest("login", payload);
        if (response !== null) {
            return true;
        }
        return false;
    }

    public static updateRoles = async (groupId: number): Promise<void> => {
        const id: IdItem = { id: groupId };
        await this.postRequest("updateRoles", id);
    }

    public static getUser = async (id: number): Promise<User | null> => {
        const idItem: IdItem = { id };
        const response = await this.postRequest("getUser", idItem);

        if (response) {
            return JSON.parse(response);
        }

        return null;
    }

    public static getUsers = async (): Promise<User[]> => {
        const response = await this.postRequest("getUsers", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static addNewAdminUser = async (newUser: User): Promise<number> => {
        const response = await this.postRequest("addAdminUser", newUser);
        if (response) {
            return JSON.parse(response);
        }
        return -1;
    }

    public static addNewUser = async (newUser: User): Promise<boolean> => {
        const response = await this.postRequest("addUser", newUser);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteUser = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("deleteUser", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static updateUser = async (user: User): Promise<boolean> => {
        const response = await this.postRequest("updateUser", user);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static reSendEmailTo = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("reSendMail", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static countQuestions = async (filter: Filter): Promise<number> => {
        const response = await this.postRequest("question/count", filter);
        if (response) {
            return JSON.parse(response);
        }
        return 0
    }

    public static getQuestions = async (filter?: Filter): Promise<Question[]> => {
        const payload: Filter = filter ? filter : { column: "", direction: "", limit: -1, offset: -1, search: "" }
        const response = await this.postRequest("question/all", payload);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getQuestonsForPhase = async (id: number): Promise<Question[]> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("phase/questions", payload);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getQuestionById = async (id: number): Promise<Question | null> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("question/get", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static addOrUpdateQuestion = async (q: Question): Promise<number> => {
        const response = await this.postRequest("content/question/save", q);
        if (response) {
            return JSON.parse(response);
        }
        return 0
    }

    public static deleteQuestion = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("question/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static addNewProject = async (p: Project): Promise<number> => {
        const response = await this.postRequest("projects/add", p);
        if (response) {
            return JSON.parse(response);
        }
        return 0;
    }

    public static cloneProject = async (p: Project): Promise<number> => {
        const response = await this.postRequest("projects/clone", p);
        if (response) {
            return JSON.parse(response);
        }
        return 0;
    }

    public static deleteProject = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("projects/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getProjects = async (): Promise<Project[]> => {
        const response = await this.postRequest("projects/all", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getProjectImage = async (projectId: number): Promise<ProjectImage | null> => {
        const response = await this.postRequest("projects/image/get", { id: projectId } as IdItem);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static setProjectImage = async (image: ProjectImage): Promise<boolean> => {
        const response = await this.postRequest("projects/image/set", image);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getChildProjects = async (id: number): Promise<Array<Project>> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("projects/children", payload);

        if (response) {
            return JSON.parse(response);
        }

        return [];
    }

    public static getMyProjects = async (): Promise<Array<ProjectWithChildren>> => {
        const response = await this.postRequest("projects/my", undefined);

        if (response) {
            return JSON.parse(response);
        }

        return [];
    }

    public static getMyProjectFamilies = async (): Promise<Array<ProjectFamily>> => {
        const response = await this.postRequest("projects/family/my", undefined);

        if (response) {
            return JSON.parse(response);
        }

        return [];
    }

    public static addProjectFamily = async (family: ProjectFamily): Promise<boolean> => {
        const response = await this.postRequest("projects/family/add", family);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static deleteProjectFamily = async (familyId: number): Promise<boolean> => {
        const payload: IdItem = { id: familyId };
        const response = await this.postRequest("projects/family/delete", payload);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static updateProjectFamily = async (family: ProjectFamily): Promise<boolean> => {
        const response = await this.postRequest("projects/family/update", family);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static linkToProjectFamily = async (tuple: ProjectFamilyTuple): Promise<boolean> => {
        const response = await this.postRequest("projects/family/project/add", tuple);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static unlinkToProjectFamily = async (tuple: ProjectFamilyTuple): Promise<boolean> => {
        const response = await this.postRequest("projects/family/project/remove", tuple);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static linkChildProject = async (parentId: number, childId: number): Promise<boolean> => {
        const payload: ProjectChildPair = { parentId, childId };
        const response = await this.postRequest("projects/link", payload);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static unlinkChildProject = async (parentId: number, childId: number): Promise<boolean> => {
        const payload: ProjectChildPair = { parentId, childId };
        const response = await this.postRequest("projects/unlink", payload);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static getGroupProjects = async (): Promise<Project[]> => {
        const response = await this.postRequest("projects/bygroup", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getMaxNumberOfProjects = async (): Promise<number> => {
        const response = await this.postRequest("projects/maxallowed", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return 0;
    }

    public static getProject = async (id: number): Promise<Project | null> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("projects/get", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static getGroupImAdministrating = async (): Promise<number> => {
        const response = await this.postRequest("group/getMyAdminGroup", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return -1
    }

    public static getMyGroup = async (): Promise<Group | null> => {
        const response = await this.postRequest("group/getMyGroup", undefined, true);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static getProjectOverview = async (id: number, type: CompassType): Promise<CompassOverview | null> => {
        const payload: IdValueItem = { id: id, value: type };
        const response = await this.postRequest("project/overview", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static setProjectPhase = async (p: Project): Promise<boolean> => {
        const response = await this.postRequest("projects/setPhase", p);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static setManagementPhase = async (managementPhase: number): Promise<boolean> => {
        const response = await this.postRequest("group/setManagementPhase", managementPhase);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static getAllGroups = async (): Promise<Group[]> => {
        const response = await this.postRequest("group/all", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getAllGroupsImAdministering = async (): Promise<Group[]> => {
        const response = await this.postRequest("group/managedAll", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getAllGroupsImIn = async (): Promise<Group[]> => {
        const response = await this.postRequest("group/self", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getAllUsersOfMyGroup = async (): Promise<User[]> => {
        const response = await this.postRequest("group/myusers", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getAllUsersOfGroupUserIsPart = async (): Promise<User[]> => {
        const response = await this.postRequest("group/allusers", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static addNewGroup = async (group: Group): Promise<boolean> => {
        const response = await this.postRequest("group/new", group);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static updateGroupUsers = async (group: Group): Promise<boolean> => {
        const response = await this.postRequest("group/usersUpdate", group);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static getMyManagedGroups = async (): Promise<Group[]> => {
        const response = await this.postRequest("group/managed", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static getGroupUsers = async (groupId: number): Promise<User[]> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("group/users", payload);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static updateGroupUser = async (groupUser: GroupUser): Promise<boolean> => {
        const response = await this.postRequest("group/updateUser", groupUser);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static getMyUser = async (): Promise<User | null> => {
        const response = await this.postRequest("user/my", undefined);

        if (response) {
            return JSON.parse(response);
        }

        return null;
    }

    public static getGroupUserWithImage = async (userId?: number): Promise<GroupUser | null> => {
        const payload: IdItem | undefined = userId ? { id: userId } : undefined;
        const response = await this.postRequest("group/userWithImage", payload);

        if (response) {
            return JSON.parse(response);
        }

        return null;
    }

    public static getGroupUserWithoutImage = async (userId?: number): Promise<GroupUser | null> => {
        const payload: IdItem | undefined = userId ? { id: userId } : undefined;
        const response = await this.postRequest("group/userWithoutImage", payload);

        if (response) {
            return JSON.parse(response);
        }

        return null;
    }

    public static updateGroup = async (group: Group): Promise<boolean> => {
        const response = await this.postRequest("group/update", group);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static updateGroupLogo = async (group: UpdateGroupLogo): Promise<boolean> => {
        const response = await this.postRequest("group/updateLogo", group);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static deleteGroup = async (groupId: number): Promise<boolean> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("group/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static changeOwnerOfGroup = async (group: Group): Promise<boolean> => {
        const response = await this.postRequest("group/delete", group);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static getGroup = async (id: number): Promise<Group | null> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("group/get", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static getCompassQuestions = async (getCompass: GetCompass): Promise<Question[]> => {
        const response = await this.postRequest("compass/all", getCompass);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static SaveCompassQuestions = async (questions: Question[]): Promise<boolean> => {
        const payload: Questions = { questions: questions };
        const response = await this.postRequest("compass/save", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static saveQuestionsForPhase = async (questions: Question[], phase: number): Promise<boolean> => {
        const payload: UpdateQuestions = { toSave: questions, phase: phase };
        const response = await this.postRequest("question/update/phase", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static distributeQuestionForContent = async (question: Question): Promise<boolean | number> => {
        const response = await this.postRequest("content/question/distribute", question);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static saveQuestionForContent = async (question: Question): Promise<boolean | number> => {
        const response = await this.postRequest("content/question/save", question);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static reviewQuestionForContent = async (id: IdItem): Promise<boolean> => {
        const response = await this.postRequest("content/question/review", id);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static getContentQuestionByFilterForSubArea = async (filter: GetContentQuestionFilterForSubArea): Promise<Question | null> => {
        const response = await this.postRequest("content/question/getForSubArea", filter);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getContentAuthors = async (filter: GetContentQuestionFilter): Promise<Array<User> | null> => {
        const response = await this.postRequest("content/question/authors", filter);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static addNewInfoRequest = async (request: InfoRequest): Promise<boolean> => {
        const response = await this.postRequest("contact", request);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static countInfoRequests = async (request: Filter): Promise<number> => {
        const response = await this.postRequest("countContacts", request);
        if (response) {
            return JSON.parse(response);
        }
        return 0
    }

    public static getInfoRequests = async (request?: Filter): Promise<InfoRequest[]> => {
        const payload: Filter = request ? request : { column: "", direction: "", limit: -1, offset: -1, search: "" }
        const response = await this.postRequest("getContacts", payload);
        if (response) {
            return JSON.parse(response);
        }
        return []
    }

    public static deleteInfoRequest = async (id: number): Promise<boolean> => {
        let payload: IdItem = { id: id };
        const response = await this.postRequest("deleteContact", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false
    }

    public static getTodos = async (): Promise<ToDo[]> => {
        const response = await this.postRequest("todo/get", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static addTodo = async (todo: ToDo): Promise<boolean> => {
        const response = await this.postRequest("todo/add", todo);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static updateTodo = async (todo: ToDo): Promise<boolean> => {
        const response = await this.postRequest("todo/update", todo);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteTodo = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("todo/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static saveAnswer = async (answer: Answer): Promise<boolean> => {
        const response = await this.postRequest("answer/save", answer);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getAnswer = async (questionID: number, phase: number, projectId: number): Promise<Answer | null> => {
        const payload: IdPhaseItem = { id: questionID, phase: phase, projectId: projectId };
        const response = await this.postRequest("answer/get", payload, true);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static saveEndAnswer = async (answer: Answer): Promise<boolean> => {
        const response = await this.postRequest("answer/end/save", answer);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getEndAnswer = async (phase: number, projectId: number, noErrorOnEmpty?: boolean): Promise<Answer | null> => {
        const payload: IdPhaseItem = { id: 0, phase: phase, projectId: projectId };
        const response = await this.postRequest("answer/end/get", payload, noErrorOnEmpty);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static requestPassReset = async (mail: string): Promise<void> => {
        await this.postRequest("reset/request", mail);
    }

    public static validPassToken = async (token: string): Promise<boolean> => {
        const response = await this.postRequest("reset/valid", token);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static changePassword = async (token: string, password: string): Promise<boolean> => {
        const payload: ResetPass = { token: token, pass: password };
        const response = await this.postRequest("reset", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static userChangePassword = async (password: string): Promise<boolean> => {
        const response = await this.postRequest("reset/user", password);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static addInfo = async (info: Info): Promise<boolean> => {
        const response = await this.postRequest("infos/add", info);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteInfo = async (id: number): Promise<boolean> => {
        let payload: IdItem = { id: id };
        const response = await this.postRequest("infos/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getInfos = async (): Promise<Info[]> => {
        const response = await this.postRequest("infos/get", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getInfosToManage = async (): Promise<Info[]> => {
        const response = await this.postRequest("infos/getAll", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getMyGroupExcel = async (): Promise<Excel | null> => {
        const response = await this.postRequest("group/excel/my", null);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getGroupExcel = async (groupId: number): Promise<Excel | null> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("group/excel", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getProjectExcel = async (projectId: number): Promise<Excel | null> => {
        const payload: IdItem = { id: projectId };
        const response = await this.postRequest("projects/excel", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static updateProjectUsers = async (project: Project): Promise<boolean> => {
        const response = await this.postRequest("project/updateusers", project);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getRequestsExcel = async (): Promise<Excel | null> => {
        const response = await this.postRequest("infos/download", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getGroupPermissions = async (): Promise<Permissions[]> => {
        const response = await this.postRequest("group/getPermissions", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getInternalGroups = async (): Promise<InternalGroup[]> => {
        const response = await this.postRequest("group/getInternalGroups", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getMyInternalGroups = async (): Promise<InternalGroup[]> => {
        const response = await this.postRequest("group/getMyInternalGroups", null);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getInternalGroup = async (groupId: number): Promise<InternalGroup | null> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("group/getInternalGroup", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static deleteInternalGroup = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("group/internalDelete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static addOrUpdateInternalGroup = async (group: InternalGroup): Promise<boolean> => {
        const response = await this.postRequest("group/internalupdate", group);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static setPermission = async (permission: Permissions): Promise<boolean> => {
        const response = await this.postRequest("permissions/add", permission);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static addDeskItem = async (item: DeskItem): Promise<boolean> => {
        const response = await this.postRequest("desk/add", item);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static updateTagsForDeskItem = async (updateTags: UpdateTags): Promise<boolean> => {
        const response = await this.postRequest("desk/tags/update", updateTags);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteDeskItem = async (id: number): Promise<boolean> => {
        const payload: IdItem = { id: id };
        const response = await this.postRequest("desk/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getAllDeskItemsForProject = async (projectId: number): Promise<DeskItem[]> => {
        const payload: IdItem = { id: projectId };
        const response = await this.postRequest("desk/all/project", payload);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getAllDeskItemsForGroup = async (groupId: number): Promise<DeskItem[]> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("desk/all/group", payload);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static deleteAllDeskItemsForProject = async (projectId: number): Promise<boolean> => {
        const payload: IdItem = { id: projectId };
        const response = await this.postRequest("desk/all/project/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteAllDeskItemsForGroup = async (groupId: number): Promise<boolean> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("desk/all/group/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getDeskItem = async (itemId: number): Promise<DeskItem | null> => {
        const payload: IdItem = { id: itemId };
        const response = await this.postRequest("desk/get", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getPollsForProjectId = async (projectId: number): Promise<Array<Poll>> => {
        const payload: IdItem = { id: projectId };
        const response = await this.postRequest("polls/get/project", payload);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getPollsForGroupId = async (groupId: number): Promise<Array<Poll>> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("polls/get/group", payload);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getPollAnswer = async (groupId: number): Promise<PollAnswer | null> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("polls/answer", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static addPoll = async (poll: Poll): Promise<number | null> => {
        const response = await this.postRequest("polls/add", poll);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static updatePoll = async (poll: Poll): Promise<boolean> => {
        const response = await this.postRequest("polls/update", poll);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deletePoll = async (pollId: number): Promise<boolean> => {
        const payload: IdItem = { id: pollId };
        const response = await this.postRequest("polls/delete", payload);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static savePollAnswer = async (answer: PollAnswer): Promise<boolean> => {
        const response = await this.postRequest("polls/save", answer);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getPollResult = async (groupId: number): Promise<PollAnswer[]> => {
        const payload: IdItem = { id: groupId };
        const response = await this.postRequest("polls/result", payload);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getPostPhaseQuestion = async (subArea: string, projectId: number): Promise<Question | null> => {
        const payload: GetPostQuestions = { subArea, projectId };
        const response = await this.postRequest("postphase/question", payload);
        if (response) {
            return JSON.parse(response);
        }
        return null
    }

    public static saveGraphImage = async (graphData: GraphImage): Promise<boolean> => {
        const response = await this.postRequest("graphs/save", graphData);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteGraphImage = async (graphData: GraphImage): Promise<boolean> => {
        const response = await this.postRequest("graphs/delete", graphData);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getGraphImage = async (userId: number, projectId: number, authorId: number, phase: number, name: string, graphFor: GraphImageFor, ignoreOnEmpty?: boolean): Promise<GraphImage | null> => {
        const payload: GraphImage = { id: -1, userId, projectId, authorId, phase, base64: "", name, graphType: "NONE", graphFor, json: "" };
        const response = await this.postRequest("graphs/get", payload, ignoreOnEmpty);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static listGraphImages = async (userId: number, projectId: number | null, phase: number, graphFor: GraphImageFor): Promise<Array<GraphImage>> => {
        const payload: GraphImage = { id: -1, userId, projectId, authorId: -1, phase, base64: "", name: "", graphType: "NONE", graphFor, json: "" };
        const response = await this.postRequest("graphs/list", payload);

        if (response) {
            return JSON.parse(response);
        }

        return [];
    }

    public static getAllOrderedLicenses = async (): Promise<Array<CircaPackage>> => {
        const response = await this.postRequest("license/ordered/all", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getAllLicenses = async (): Promise<Array<CircaPackage>> => {
        const response = await this.postRequest("license/all", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getLicense = async (): Promise<CircaPackage | null> => {
        const response = await this.postRequest("license/get", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getLicenseMetaData = async (): Promise<LicenseMetaData | null> => {
        const response = await this.postRequest("license/meta", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getPackagePrices = async (): Promise<Array<PackagePrice>> => {
        const response = await this.postRequest("package/prices/get", undefined);
        if (response) {
            return JSON.parse(response);
        }

        return [];
    }

    public static savePackagePrices = async (prices: Array<PackagePrice>): Promise<boolean> => {
        const response = await this.postRequest("package/prices/save", prices);
        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static makeOrderRequest = async (order: OrderRequest): Promise<boolean> => {
        const response = await this.postRequest("services/order/new", order);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getLicencesForOrder = async (orderId: number): Promise<Array<CircaPackage>> => {
        const item: IdItem = { id: orderId }
        const response = await this.postRequest("services/orders/licences", item);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static updatePaid = async (orderId: number, paid: string | null): Promise<boolean> => {
        const item: UpdatePaid = { orderId, paid };
        let response = await this.postRequest("services/orders/paid", item);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static activateOrder = async (orderId: number): Promise<boolean> => {
        const item: IdItem = { id: orderId }
        let response = await this.postRequest("services/orders/activate", item);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deactivateOrder = async (orderId: number): Promise<boolean> => {
        const item: IdItem = { id: orderId }
        let response = await this.postRequest("services/orders/deactivate", item);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getCustomer = async (id: number): Promise<Customer | null> => {
        const idItem = { id };
        let response = await this.postRequest("services/customers/get", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getCustomers = async (): Promise<Customer[]> => {
        let response = await this.postRequest("services/customers/all", undefined);
        if (response) {
            let data: Customer[] = JSON.parse(response)
            return data;
        }
        return [];
    }

    public static getMyCustomer = async (): Promise<Customer | null> => {
        let response = await this.postRequest("services/customers/my", undefined);
        if (response) {
            let data: Customer = JSON.parse(response)
            return data;
        }
        return null;
    }

    public static updateCustomer = async (customerAndManager: CustomerAndManager): Promise<boolean> => {
        let response = await this.postRequest("services/customers/update", customerAndManager);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static addOrUpdatePackage = async (circaPackage: CircaPackage): Promise<boolean> => {
        let response = await this.postRequest("services/packages/addOrUpdate", circaPackage);

        if (response) {
            return JSON.parse(response);
        }

        return false;
    }

    public static saveInvoiceSettings = async (settings: SettingsInvoice): Promise<boolean> => {
        let response = await this.postRequest("settings/save", settings);

        if (response) {
            return JSON.parse(response) as boolean;
        }

        return false;
    }

    public static getInvoiceSettings = async (): Promise<SettingsInvoice | null> => {
        let response = await this.postRequest("settings/getInvoice", undefined);
        if (response) {
            return JSON.parse(response) as SettingsInvoice;
        }
        return null;
    }

    public static saveToWay = async (entry: ProjectWayEntry): Promise<boolean> => {
        let response = await this.postRequest("project/way/save", entry);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteFromWay = async (entry: ProjectWayEntry): Promise<boolean> => {
        let response = await this.postRequest("project/way/delete", entry);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getProjectWay = async (projectId: number): Promise<Array<ProjectWayEntry>> => {
        const item: IdItem = { id: projectId };
        let response = await this.postRequest("project/way/get", item);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getProjectWayEntry = async (query: GetWayEntry): Promise<ProjectWayEntry | null> => {
        let response = await this.postRequest("project/way/query", query);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getManagmentContent = async (groupId: number, managementPhase: ManagementPhase): Promise<ManagementPhaseContent | null> => {
        const obj: GetManagementPhaseContent = { groupId, managementPhase }
        let response = await this.postRequest("managementCockpit/get", obj);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static saveManagmentContent = async (content: ManagementPhaseContent): Promise<boolean> => {
        let response = await this.postRequest("managementCockpit/save", content);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static transferSettings = async (otherGroupId: number): Promise<boolean> => {
        const idItem: IdItem = { id: otherGroupId }
        let response = await this.postRequest("transfer/settings", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static transferManagementContent = async (otherGroupId: number): Promise<boolean> => {
        const idItem: IdItem = { id: otherGroupId }
        let response = await this.postRequest("transfer/managementContent", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static transferContent = async (otherGroupId: number, area: Area, subAreaName: string): Promise<boolean> => {
        const transfer: TransferContent = { groupId: otherGroupId, area, subAreaName }
        let response = await this.postRequest("transfer/content", transfer);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getTaxes = async (): Promise<Array<Tax>> => {
        let response = await this.postRequest("services/tax/get", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static addTax = async (tax: Tax): Promise<boolean> => {
        let response = await this.postRequest("services/tax/add", tax);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static deleteTax = async (id: number): Promise<boolean> => {
        let idItem: IdItem = { id };
        let response = await this.postRequest("services/tax/delete", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return false;
    }

    public static getNumberOfOpenContractMonths = async (items: Array<OrderItem>): Promise<number | null> => {
        let query: RangeQuery = { items };
        let response = await this.postRequest("services/order/queryMonths", query);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getOffer = async (orderRequest: OrderRequest): Promise<string | null> => {
        let response = await this.postRequest("services/order/offer", orderRequest);
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    public static getOrderAnalysisForCustomer = async (id: number): Promise<Array<AnalysisOrder>> => {
        const idItem: IdItem = { id };
        let response = await this.postRequest("services/order/analysis/customer", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getOrderAnalysisForCustomerType = async (type: TypeCustomer): Promise<Array<AnalysisOrder>> => {
        let response = await this.postRequest("services/order/analysis/customerType", type);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getMyOrderAnalysis = async (): Promise<Array<AnalysisOrder>> => {
        let response = await this.postRequest("services/order/analysis/my", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getPackageAnalysisForCustomer = async (id: number): Promise<Array<AnalysisPackage>> => {
        const idItem: IdItem = { id };
        let response = await this.postRequest("services/package/analysis/customer", idItem);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getPackageAnalysisForModule = async (type: LicenseTypes): Promise<Array<AnalysisPackage>> => {
        let response = await this.postRequest("services/package/analysis/module", type);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getMyPackageAnalysis = async (): Promise<Array<AnalysisPackage>> => {
        let response = await this.postRequest("services/package/analysis/my", undefined);
        if (response) {
            return JSON.parse(response);
        }
        return [];
    }

    public static getTotalLicenses = async (): Promise<TotalLicenses | null> => {
        let response = await this.postRequest("license/total", undefined);

        if (response) {
            return JSON.parse(response);
        }

        return null;
    }
}
