import { useContext, useEffect, useState } from "react";
import { sendOlert } from "../../olert/Olert";
import { CircalindApi } from "../circalindApi";
import { GroupUser, Question, TableCellType, TableData } from "../dataTypes/generated";
import { CompassTable } from "../projects/compass/table/CompassTable";
import "./GeneralContent.scss";
import { TableAnswerCell } from "../hooks/useAnswer";
import { getCurrentUserId } from "../../utils/Auth";
import { ImpulseContext } from "../../MainCircaLind";

interface GeneralContentProps {
    canDistribute?: boolean;
    canEdit: boolean;
    canReview: boolean;
    showControls: boolean;
    question: Question;
    changeVersion?: (version: string) => void;
    changeTitle?: (title: string) => void;
    allowedCellTypes?: TableCellType[];
    onUpdate?: (data: TableData) => void;
    onUpdateId?: (id: number) => void;
    answers?: Array<TableAnswerCell>;
    onCellAnswerd?: (answers: Array<TableAnswerCell>) => void;
    onSaveAnswers?: () => void;
    onShowReviewers?: () => void;
}

export const GeneralContent = ({ canDistribute, canReview, canEdit, question, showControls, changeVersion, changeTitle, onUpdate, allowedCellTypes, answers, onCellAnswerd, onSaveAnswers, onShowReviewers, onUpdateId }: GeneralContentProps) => {
    const [edit, setEdit] = useState<boolean>(false);
    const [owner, setOwner] = useState<GroupUser | null>(null);
    const [reviewer, setReviewer] = useState<GroupUser | null>(null);

    const impulseContext = useContext(ImpulseContext);

    useEffect(() => {
        const getOwnerAndReviewer = async () => {
            if (question.id > -1) {
                if (question.owner > 0) {
                    const owner = await CircalindApi.getGroupUserWithoutImage(question.owner);
                    setOwner(owner);
                    if (impulseContext && owner) {
                        impulseContext.setImpulseColor(owner.color)
                    }
                } else {
                    setOwner(null);
                }

                if (question.reviewedBy > 0) {
                    const reviewer = await CircalindApi.getGroupUserWithoutImage(question.reviewedBy);
                    setReviewer(reviewer);
                } else {
                    setReviewer(null);
                }
            }
        };

        getOwnerAndReviewer();

        return () => {
            if (impulseContext) {
                impulseContext.setImpulseColor(undefined)
            }
        };
    }, [impulseContext, question]);

    const renderRequestReview = () => {
        if (canReview && edit && onShowReviewers) {
            return <button onClick={() => onShowReviewers()}>Freigabe anfragen</button>;
        };
    }

    const renderReview = () => {
        const onReview = async () => {
            const result = await CircalindApi.reviewQuestionForContent({ id: question!.id });
            if (result) {
                sendOlert("Erfolgreich freigegeben", "Inhalt erfolgreich freigegeben.", "Success");
            }
        };

        if (canReview && edit) {
            return <button onClick={onReview}>Freigeben</button>;
        };
    }

    const renderSave = () => {
        const onSave = async () => {
            const result = await CircalindApi.saveQuestionForContent({ ...question, owner: getCurrentUserId("token-circalind") || -1 }!);

            if ((typeof result === "boolean" && result === true) || (typeof result === "number" && result > 0)) {
                sendOlert("Erfolgreich gespeichert", "Inhalt erfolgreich gespeichert.", "Success");
            }

            if ((typeof result === "number" && result > 0) && onUpdateId) {
                onUpdateId(result);
            }
        };

        if (canEdit && edit) {
            return <button onClick={() => { onSave() }}>Speichern</button>;
        } else if (onSaveAnswers) {
            return <button onClick={() => { onSaveAnswers() }}>Speichern</button>;
        }
    };

    const renderDistribute = () => {
        const onDistribute = async () => {
            const result = await CircalindApi.distributeQuestionForContent({ ...question, owner: getCurrentUserId("token-circalind") || -1 }!);

            if ((typeof result === "boolean" && result === true) || (typeof result === "number" && result > 0)) {
                sendOlert("Erfolgreich gespeichert", "Inhalt erfolgreich gespeichert.", "Success");
            }

            if ((typeof result === "number" && result > 0) && onUpdateId) {
                onUpdateId(result);
            }
        };

        if (canDistribute && edit) {
            return <button onClick={() => { onDistribute() }}>Freigeben und Verteilen</button>;
        }
    };

    const renderPreview = () => {
        return <button onClick={() => { setEdit(!edit) }}>{edit ? "Vorschau" : "Eingeben"}</button>;
    };

    const renderGroupUser = (groupUser: GroupUser | null) => {
        if (groupUser) {
            const elements = [[groupUser.firstName, groupUser.lastName].filter(s => s).join(" "), groupUser.company, groupUser.position].filter(s => s).join(", ");
            return <span>{elements}</span>
        }
    }

    const renderOwner = () => {
        const renderEditedOn = (question: Question) => {
            return question.editedOn ? new Date(question.editedOn).toLocaleDateString() : "";
        };

        return <div>
            {owner && question.id > -1 ? <span>Erstellt durch: {renderGroupUser(owner)} am {renderEditedOn(question)}</span> : undefined}
        </div>;
    };

    const renderReviewer = () => {
        const renderReviewedOn = (question: Question) => {
            return question.reviewedOn ? new Date(question.reviewedOn).toLocaleDateString() : "";
        };

        return <div>
            {reviewer && question.id > -1 ? <span>Freigebeben durch: {renderGroupUser(reviewer)} am {renderReviewedOn(question)}</span> : undefined}
        </div>;
    };

    const renderVersionAndTitle = () => {
        const versionCallback = (e: any) => {
            if (changeVersion) {
                changeVersion(e.target.value);
            }
        }

        const titleCallback = (e: any) => {
            if (changeTitle) {
                changeTitle(e.target.value);
            }
        }

        if (edit) {
            return <div className="heading">
                <div className="version">
                    <input disabled={changeVersion === undefined} onChange={versionCallback} placeholder="Version" value={question!.version || ""} />
                </div>
                <div className="title">
                    {changeTitle && <input onChange={titleCallback} placeholder="Titel" value={question!.title || ""} />}
                </div>
            </div>
        } else {
            return <div className="heading">
                <div className="version">{question!.version ? `Version: ${question!.version}` : ""}</div>
                <div className="title"><h3>{question!.title}</h3></div>
            </div>;
        }
    }

    const renderTableOrMessage = () => {
        if (question.id > -1 || question.table.tableCells.length > 0 || edit) {
            if (!edit && !canEdit && question.owner > 0 && !(question.reviewedBy > 0)) {
                return <div className="no-content">Inhalt nicht freigegeben.</div>
            } else {
                return <CompassTable allowedCellTypes={allowedCellTypes} edit={edit} data={question.table} answers={answers ? answers : []} onCellAnswerd={onCellAnswerd ? onCellAnswerd : () => { }} onUpdate={onUpdate || (() => { })} />;
            }
        } else {
            return <div className="no-content">Kein Inhalt verfügbar</div>
        }
    };

    const renderContent = () => {
        return <>
            {renderVersionAndTitle()}
            {renderTableOrMessage()}
            {showControls ? <div className="preview-button-container">
                {renderRequestReview()}
                {renderDistribute()}
                {renderSave()}
                {renderPreview()}
                {renderReview()}
            </div> : undefined}
            {question.owner > 0 || question.reviewedBy > 0 ?
                <div className="owner-info">
                    <div>{renderOwner()}</div>
                    <div>{renderReviewer()}</div>
                </div>
                : undefined}
        </>
    }

    return (
        <div className="content-wrapper">
            {renderContent()}
        </div>
    );
};

