import { faTrashAlt, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useState } from "react";
import { CircalindApi } from "../../circalind/circalindApi";
import { GroupUser, InternalGroup, User } from "../../circalind/dataTypes/generated";
import { sendOlert } from "../../olert/Olert";
import { StaffingDialogProps } from "../staffing/Staffing";
import { UserSelect } from "./UserSelect";

import "./EditStaffingGroup.scss";

export const EditStaffingGroup = (props: StaffingDialogProps): ReactElement => {

    const empty: InternalGroup = { id: -1, groupId: -1, name: "", ownerId: -1, templateUserId: -1, members: [] }

    const getDefaultOrSelected = (): InternalGroup => {
        if (props.selected !== undefined) {
            return props.selected as InternalGroup;
        }
        return empty
    }

    const [group, setGroup] = useState<InternalGroup>(getDefaultOrSelected());
    const [user, setUser] = useState<User | undefined>(undefined);

    const addUser = () => {
        if (user) {
            const groupUser: GroupUser = { email: user.mail, firstName: user.firstName, groupId: group.id, roles: [], lastName: user.lastName, id: user.id, company: "", description: "", position: "", image: "", color: "" }
            const index = group.members.findIndex(m => m.email === user.mail)
            if (index === -1) {
                let newGroup = { ...group };
                newGroup.members.push(groupUser);
                setGroup(newGroup);
            }
        }
    }

    const removeUser = (user: GroupUser) => {
        setGroup({
            ...group,
            members: group.members.filter(m => m.id !== user.id)
        });
    }


    const onSave = async () => {
        if (group && group.name) {
            const res = await CircalindApi.addOrUpdateInternalGroup(group);
            if (res === true) {
                sendOlert("Erfolgreich", "Personenkreis gespeichert", "Success");
                setGroup(empty)
            } else {
                sendOlert("Fehler", "Personenkreis konnte nicht gespeichert werden", "Error");
            }
            props.onUpdate();
        } else {
            sendOlert("Fehler", "Personenkreis muss einen Namen haben", "Error");
        }
    }

    const renderUser = (user: GroupUser, index: number) => {
        return <div className="list-entry" key={index}>
            <div key={index} className="user-entry">
                <span>{user.firstName + " " + user.lastName}</span>
                <label>{user.email}</label>
            </div>
            <FontAwesomeIcon icon={faTrashAlt} onClick={() => removeUser(user)} />
        </div>
    }

    return <div className="new-staffing-group">
        <div className="inputs">
            <h3>Personenkreise</h3>
            <div className="input">
                <label>Name</label>
                <input value={group.name} onChange={(e) => setGroup({ ...group, name: e.target.value })} />
            </div>
            <div className="input">
                <label>Verantwortlicher (Optional)</label>
                <UserSelect useName={true} valueUserId={group.ownerId} users={props.users} onSelect={(e) => setGroup({ ...group, ownerId: e ? e.id : -1 })} />
            </div>
            <div className="input">
                <label>Musterperson (Optional)</label>
                <UserSelect useName={true} valueUserId={group.templateUserId} users={props.users} onSelect={(e) => setGroup({ ...group, templateUserId: e ? e.id : -1 })} />
            </div>
            <h3>Nutzer</h3>
            <div className="users">
                <div className="add-user-panel">
                    <UserSelect useName={true} users={props.users} onSelect={setUser} valueUserId={user?.id} />
                    <div className="add-button" onClick={() => addUser()}><FontAwesomeIcon icon={faPlusSquare} /></div>
                </div>
                <div className="list">
                    {group.members.map((u, i) => renderUser(u, i))}
                </div>
            </div>
        </div>
        <div className="buttons">
            <button onClick={() => props.onCancel()}>Abbrechen</button>
            <button onClick={() => onSave()}>Speichern</button>
        </div>
    </div>
}
